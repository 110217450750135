// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-downloadable-schedule-js": () => import("./../../../src/pages/downloadable-schedule.js" /* webpackChunkName: "component---src-pages-downloadable-schedule-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-templates-activity-js": () => import("./../../../src/templates/activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-building-js": () => import("./../../../src/templates/building.js" /* webpackChunkName: "component---src-templates-building-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-deal-js": () => import("./../../../src/templates/deal.js" /* webpackChunkName: "component---src-templates-deal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-ride-js": () => import("./../../../src/templates/ride.js" /* webpackChunkName: "component---src-templates-ride-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-subcategory-js": () => import("./../../../src/templates/subcategory.js" /* webpackChunkName: "component---src-templates-subcategory-js" */),
  "component---src-templates-universal-tag-js": () => import("./../../../src/templates/universal-tag.js" /* webpackChunkName: "component---src-templates-universal-tag-js" */),
  "component---src-templates-vendor-js": () => import("./../../../src/templates/vendor.js" /* webpackChunkName: "component---src-templates-vendor-js" */)
}

